import React from "react";
import { Link } from "react-router-dom";
import { ChakraProvider,Image, HStack, Menu, MenuItem, MenuButton, MenuList, IconButton, Box, Text,Button,Modal,ModalOverlay,useDisclosure,ModalContent,ModalCloseButton,ModalBody, VStack,Heading } from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons';
import theme from "../../Theme";
import { HashLink } from 'react-router-hash-link';
import Logo from "../parts/Logo";
import { modalHeadingTxtImageButton,modalHeadingTxtSns,modalForCompany } from "../parts/Main";
import ButtonDownload from "../parts/ButtonDownload";
import daisan_ticket from "../file/daisan_ticket.pdf"

const Links = [
  { label:"2023", path:`/`},
];


const Header = () => {

  const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
        <ChakraProvider theme={theme}>
            <HStack
            justifyContent="space-between"
            fontSize='sm'
            py = {4}
            bg='bg'
            as='header'
            position="fixed"
            zIndex={100}
            w='100vw'
            px={{base:"7%", md:"10%", lg:"15%", xl:"17.5%", '2xl':"25%"}}
            borderBottom="1px solid #D3D3D3"
            >
              <HStack spacing={{base:3, md:4}}>
                <Link to={`/`}>
                    <Image
                      src={`/img/logo-katei.png`}
                      alt='ロゴ'
                      h={{base:18, lg:22}}
                      objectFit="contain"
                    />
                </Link>
                <Box
                  w="1px"
                  h={4}
                  bg="line"
                />
                <Logo
                  size={{base:3, lg:4}}
                />
              </HStack>
                <HStack spacing={2}>
                    <HashLink smooth to={`/`} >
                      <Button
                        onClick={onOpen}
                        bg="black"
                        px={3}
                        py={2}
                        color="white"
                        fontSize={14}
                        borderRadius='8px'
                        lineHeight={1.7}
                        fontWeight="400"
                      >
                          <Text display={{base:"none", md:"block"}}>応援する</Text>
                          <Text display={{base:"block", md:"none"}}>応援</Text>
                      </Button>
                      <Modal isOpen={isOpen} onClose={onClose} size={{base:'sm', md:'md', lg:'xl'}} scrollBehavior='inside'>
                        <ModalOverlay />
                        <ModalContent py={4} borderRadius='8px'>
                            <ModalCloseButton />
                            <ModalBody>
                            <VStack spacing={14} p={4} alignItems="flex-start">
                            {modalHeadingTxtImageButton(
                              "月額100円の寄付",
                              "第3の家族では月額100円の寄付を募集しております。こちらは活動資金のためというよりは、この問題に関心を持つ人を増やしたい思いで行っております。不定期のメール配信と、毎年3月にはお礼の手紙を郵送（またはメール）しますので、よろしければお願いします。いつでも解約は可能です。",
                              "/img/letter.png",
                              "https://buy.stripe.com/fZe9BV5Fdamq0JGcMO",
                              "月額100円の寄付をする"
                              )}
                            {modalHeadingTxtImageButton(
                              "年額3,000円の寄付",
                              "第3の家族では年額3,000円の寄付を募集しております。寄付者様用のSlackチャンネルにご招待します。いつでも解約は可能です。",
                              "/img/member.png",
                              "https://buy.stripe.com/eVa8xRd7F3Y2csobIQ",
                              "年額3,000円の寄付をする"
                              )}
                            {modalHeadingTxtImageButton(
                              "第3の家族グッズ",
                              "第3の家族のグッズもあります。第3の家族の中には数名のデザイナーがおり、それぞれのクリエイティブを担当しています。こちらの売り上げを活動費に利用しますので、よろしければご覧ください。",
                              "/img/goods.png",
                              "https://suzuri.jp/daisan_kazoku",
                              "グッズ購入ページへ"
                              )}
                            <VStack spacing={4} alignItems="flex-start">
                                <VStack spacing={2} alignItems="flex-start">
                                    <Heading as="h1" variant='xl'>サービス紹介チケット</Heading>
                                    <Text lineHeight='1.7' fontSize='md'>第3の家族のサービスを紹介するチケットです。チケットやしおりのような見た目で、少年少女が手に取りたくなる・持ち続けたくなるデザインを心がけています。</Text>
                                </VStack>
                                <Image src="/img/ticket.png" alt="画像" w="100%"/>
                                <ButtonDownload href={daisan_ticket} name="daisan_ticket" text="ダウンロード"/>
                            </VStack>
                            {modalForCompany()}
                            {modalHeadingTxtImageButton(
                              "スタッフ募集",
                              "スタッフも通年募集しております。当事者が多いですが、それ以外の方も歓迎です。それぞれのスタッフが自分の思いや強みを発揮して、ものづくりを行なっています。",
                              "/img/portfolio.png",
                              "https://docs.google.com/forms/d/e/1FAIpQLSeEoQNEN6EpBZTUhlrSDQJrBMe4sCysIiVnEg8qJaupTBtf_g/viewform?usp=sf_link",
                              "スタッフ募集へ"
                              )}
                              {modalHeadingTxtSns()}
                                </VStack>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                    </HashLink>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon/>}
                            variant='outline'
                            bg='whiteAlpha.700'
                            borderRadius='8px'
                            borderColor="line"
                        />
                        <MenuList p={5} borderRadius='8px' borderColor="line">
                            {Links.map((link) => (
                              <MenuItem key={link.label} fontSize={13}><Link to={link.path}>{link.label}</Link></MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </HStack>
            </HStack>

        </ChakraProvider>
        </>
    );
};

export default Header;
