import React from "react";
import { Link } from "react-router-dom";
import {ChakraProvider,HStack,Flex,VStack,Text,Image,Modal,ModalOverlay,useDisclosure,ModalContent,ModalCloseButton,ModalBody,Heading, Button} from "@chakra-ui/react";
import theme from "../../Theme";
import Sns from "../parts/Sns";
import NewhopeEx from "../parts/NewhopeEx";
import ForbesEx from "../parts/ForbesEx";
import Logo from "../parts/Logo";
import ButtonEx from "../parts/ButtonEx";
import { modalHeadingTxtImageButton,modalHeadingTxtSns,modalForCompany } from "../parts/Main";
import ButtonDownload from "../parts/ButtonDownload";
import daisan_ticket from "../file/daisan_ticket.pdf"

const Footer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
        <ChakraProvider theme={theme}>
            <VStack
                bg="bg"
                as='footer'
                spacing={16}
                w='100vw'
                px={{base:"7%", md:"10%", lg:"15%", xl:"17.5%", '2xl':"25%"}}
                borderTop="1px solid #DFDFDF"
                pt={{ base:12, lg:16 }}
                mt={{ base:16, lg:20 }}
            >
                <Flex flexDirection={{ base: "column", lg: "row" }} justifyContent={{ lg: "space-between" }} gap={{ base:16,lg:0 }}fontSize= 'xs' pb={3} w='100%'>
                    <VStack spacing={{ base:10,md:12 }} alignItems='flex-start'>
                        <Logo size={{base:5, lg:6}}/>
                        <VStack spacing={6}>
                            <Flex flexDirection={{ base: "column", md: "row" }} gap={{ base:2, md:3 }} w="100%" alignItems="flex-start">
                                <Button
                                onClick={onOpen}
                                bg="black"
                                px={4}
                                py={3}
                                color="white"
                                fontSize={{base:14, md:16}}
                                borderRadius={{ base:8, md:10, lg:10 }}
                                lineHeight={1.7}
                                fontWeight="400"
                                w={{ base:"100%", md:300 }}
                                h={{base:12,md:14}}
                                >
                                    <Text>応援する</Text>
                                </Button>
                                <Modal isOpen={isOpen} onClose={onClose} size={{base:'sm', md:'md', lg:'xl'}} scrollBehavior='inside'>
                                    <ModalOverlay />
                                    <ModalContent py={4} borderRadius='8px'>
                                        <ModalCloseButton />
                                        <ModalBody>
                                        <VStack spacing={14} p={4} alignItems="flex-start">
                                        {modalHeadingTxtImageButton(
                                        "月額100円の寄付",
                                        "第3の家族では月額100円の寄付を募集しております。こちらは活動資金のためというよりは、この問題に関心を持つ人を増やしたい思いで行っております。不定期のメール配信と、毎年3月にはお礼の手紙を郵送（またはメール）しますので、よろしければお願いします。いつでも解約は可能です。",
                                        "/img/letter.png",
                                        "https://buy.stripe.com/fZe9BV5Fdamq0JGcMO",
                                        "月額100円の寄付をする"
                                        )}
                                        {modalHeadingTxtImageButton(
                                        "年額3,000円の寄付",
                                        "第3の家族では年額3,000円の寄付を募集しております。寄付者様用のSlackチャンネルにご招待します。いつでも解約は可能です。",
                                        "/img/member.png",
                                        "https://buy.stripe.com/eVa8xRd7F3Y2csobIQ",
                                        "年額3,000円の寄付をする"
                                        )}
                                        {modalHeadingTxtImageButton(
                                        "第3の家族グッズ",
                                        "第3の家族のグッズもあります。第3の家族の中には数名のデザイナーがおり、それぞれのクリエイティブを担当しています。こちらの売り上げを活動費に利用しますので、よろしければご覧ください。",
                                        "/img/goods.png",
                                        "https://suzuri.jp/daisan_kazoku",
                                        "グッズ購入ページへ"
                                        )}
                                        <VStack spacing={4} alignItems="flex-start">
                                            <VStack spacing={2} alignItems="flex-start">
                                                <Heading as="h1" variant='xl'>サービス紹介チケット</Heading>
                                                <Text lineHeight='1.7' fontSize='md'>第3の家族のサービスを紹介するチケットです。チケットやしおりのような見た目で、少年少女が手に取りたくなる・持ち続けたくなるデザインを心がけています。</Text>
                                            </VStack>
                                            <Image src="/img/ticket.png" alt="画像" w="100%"/>
                                            <ButtonDownload href={daisan_ticket} name="daisan_ticket" text="ダウンロード"/>
                                        </VStack>
                                        {modalForCompany()}
                                        {modalHeadingTxtImageButton(
                                        "スタッフ募集",
                                        "スタッフも通年募集しております。当事者が多いですが、それ以外の方も歓迎です。それぞれのスタッフが自分の思いや強みを発揮して、ものづくりを行なっています。",
                                        "/img/portfolio.png",
                                        "https://docs.google.com/forms/d/e/1FAIpQLSeEoQNEN6EpBZTUhlrSDQJrBMe4sCysIiVnEg8qJaupTBtf_g/viewform?usp=sf_link",
                                        "スタッフ募集へ"
                                        )}
                                        {modalHeadingTxtSns()}
                                            </VStack>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                                <ButtonEx href={`https://suzuri.jp/daisan_kazoku`} text="グッズ購入"/>
                            </Flex>
                            <Flex flexDirection={{ base: "column", md: "row" }} gap={3}>
                                <NewhopeEx/>
                                <ForbesEx/>
                            </Flex>
                        </VStack>
                    </VStack>
                    <HStack spacing={14} alignItems='flex-start'>
                        <VStack spacing={5} alignItems='flex-start'>
                            <Link to={`/`}>2023</Link>
                        </VStack>
                    </HStack>
                </Flex>
                <Flex flexDirection={{ base: "column-reverse", lg: "row" }} alignItems={{ base:"flex-start",lg: "flex-end" }} justifyContent={{ lg: "space-between" }} gap={{ base:6,lg:0 }} w='100%' pb={{base:16,md:12}}>
                    <HStack gap={2} color="fgray.80" fontSize={10}>
                        <Text>© 2023 NPO Daisan no kazoku</Text>
                        <Link to={`/terms`}>利用規約</Link>
                        <Link to={`/privacy`}>プライバシーポリシー</Link>
                    </HStack>
                    <HStack spacing={8}>
                        <Sns href={'https://twitter.com/daisan_kazoku'} src={'/img/x.png'} alt={"X"}/>
                        <Sns href={'https://www.youtube.com/@daisan_kazoku'} src={'/img/YouTube.png'}  alt={"YouTube"}/>
                        <Sns href={'https://www.instagram.com/daisan_kazoku/'} src={'/img/Instagram.png'}  alt={"Instagram"}/>
                        <Sns href={'https://www.tiktok.com/@daisan_kazoku'} src={'/img/TikTok.png'}  alt={"TikTok"}/>
                    </HStack>
                </Flex>
            </VStack>
        </ChakraProvider>
        </>
    );
};

export default Footer;
