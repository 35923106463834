import {Text, Box, VStack, Heading} from '@chakra-ui/react'

const Terms = () => {
    return(
        <>
        <Box h={28}></Box>
        <VStack
        w='100vw'
        spacing={8}
        alignItems="flex-start"
        px={{base:"7%", md:"10%", lg:"15%", xl:"17.5%", '2xl':"25%"}}>
            <Heading as="h2" variant='lg'>利用規約</Heading>
            <VStack alignItems="flex-start">
                <Text as='b'>一般利用規約</Text>
                <Text>この利用規約（以下、「本規約」といいます。）は、本サービス（本サイトを含むものとし、以下、特に両者を区別しません。）の利用条件を定めるものです。本規約は、本サービスを利用するすべてのユーザーに適用されます。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>本規約への同意</Text>
                <Text>ユーザーは、本サービスを利用することによって、本規約に有効かつ取り消し不能な同意をしたものとみなされます。本規約に同意しないユーザーは、本サービスをご利用いただけません。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>未成年による利用</Text>
                <Text>ユーザーが未成年である場合には、法定代理人の同意を得た上で、本サービスを利用してください。法定代理人の同意を得ずに本サービスのご利用を開始したユーザーが成年に達した場合、未成年者であった間の利用行為を追認したものとみなします</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>コンテンツのご利用</Text>
                <Text>当社は、ユーザーに対し、本サービスが提供する文章、画像、動画、音声、音楽、ソフトウェア、プログラム、コードその他のコンテンツについて、本サービスの利用範囲内における私的な利用及び当社が公開する本サービスによって得られた調査結果の非営利目的での利用を許諾します。有償コンテンツについては、当社が定める利用料金の支払が完了した場合に、本サービスの利用範囲内における私的な利用を許諾します。これは、譲渡及び再許諾できない、非独占的な利用権です。この範囲を超えて本サービスが提供するコンテンツを利用することは一切禁止します。理由の如何を問わず、ユーザーが本サービスを利用する権利を失った場合、本サービスの一切のコンテンツの利用ができなくなることを、ユーザーは予め承諾するものとします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>禁止事項</Text>
                <Text>ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません</Text>
                <Text>●法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為又はこれらを助長する行為</Text>
                <Text>●犯罪行為に関連する行為</Text>
                <Text>●当社や第三者の知的財産権を侵害する行為</Text>
                <Text>●当社や第三者の肖像権、プライバシー、名誉、その他の権利又は利益を侵害する行為</Text>
                <Text>●当社や第三者のサーバー又はネットワークに過度の負担をかけたり、その正常な作動を妨害する行為</Text>
                <Text>●当社のサービスの運営を妨害するおそれのある行為</Text>
                <Text>●不正アクセスをし、又はこれを試みる行為</Text>
                <Text>●逆アセンブル、逆コンパイル、リバースエンジニアリング等によって本サービスのソースコードを解析する行為</Text>
                <Text>●本サービスに接続しているシステムに権限なく不正にアクセスし又は当社設備に蓄積された情報を不正に書き換え若しくは消去する行為</Text>
                <Text>●本サービスのウェブサイトやソフトウェアを複製、送信、譲渡、貸与又は改変する行為</Text>
                <Text>●本サービス上のアカウント又はコンテンツを第三者に有償で貸与、譲渡、売買等をする行為</Text>
                <Text>●本サービスによって得られた情報を改ざんし又は商業的に利用する目的で、複製、販売、出版その他利用する行為</Text>
                <Text>●当社が意図しない方法によって本サービスに関連して利益を得ることを目的とする行為</Text>
                <Text>●当社が許諾しない本サービス上での宣伝、広告、勧誘、又は営業行為</Text>
                <Text>●他のユーザーに関する個人情報等を収集又は蓄積する行為</Text>
                <Text>●違法、不正又は不当な目的を持って本サービスを利用する行為</Text>
                <Text>●本サービスの他のユーザー又はその他の第三者（回答者を含みます。）に不利益、損害、不快感を与える行為</Text>
                <Text>●回答者に対するストーキング行為、又はその他の身体、生命、自由、名誉、財産などに対して害悪を加える行為</Text>
                <Text>●他のユーザーに成りすます行為</Text>
                <Text>●他のユーザーのアカウントを利用する行為</Text>
                <Text>●面識のない異性との出会いを目的とした行為</Text>
                <Text>●反社会的勢力に対して直接又は間接に利益を供与する行為</Text>
                <Text>●公序良俗に違反する行為</Text>
                <Text>●歩行中、車両運転中、その他本サービスの利用が不適切な状況又は態様において本サービスを利用する行為</Text>
                <Text>●その他、当社が不適切と判断する行為</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>反社会的勢力の排除</Text>
                <Text>ユーザーは、次の各号のいずれか一にも該当しないことを表明し、かつ将来にわたっても該当しないことを表明し、保証するものとします。</Text>
                <Text>●ユーザーが法人その他の団体の場合にあっては、反社会的勢力が経営を支配していると認められる関係を有すること</Text>
                <Text>●ユーザーが法人その他の団体の場合にあっては、反社会的勢力が経営に実質的に関与していると認められる関係を有すること</Text>
                <Text>●自ら若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもって取引を行うなど、反社会的勢力を利用していると認められる関係を有すること</Text>
                <Text>●反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること</Text>
                <Text>●ユーザーが法人その他の団体の場合にあっては、自らの役員又は自らの経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有することユーザーは、自ら又は第三者を利用して次の各号のいずれか一にでも該当する行為を行わないことを保証するものとします。</Text>
                <Text>●暴力的な要求行為</Text>
                <Text>●法的な責任を超えた不当な要求行為</Text>
                <Text>●取引に関して、脅迫的な言動をし、又は暴力を用いる行為</Text>
                <Text>●風説を流布し、偽計を用い、又は威力を用いて、当社の信用を毀損し、又は当社の業務を妨害する行為</Text>
                <Text>●その他前各号に準ずる行為</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>利用制限</Text>
                <Text>当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部若しくは一部の利用を制限することができるものとします。当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。</Text>
                <Text>●本規約のいずれかの条項に違反した場合</Text>
                <Text>●登録事項に虚偽の事実があることが判明した場合</Text>
                <Text>●当社からの連絡に対し、相当の期間が経過しても返答がない場合</Text>
                <Text>●最終のご利用日から相当期間、本サービスのご利用がない場合</Text>
                <Text>●反社会的勢力等であるか、反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合</Text>
                <Text>●その他、当社が本サービスの利用を適当でないと判断した場合</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>本サービスの提供の停止</Text>
                <Text>当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部又は一部の提供を停止又は中断することができるものとします。当社は、この場合にユーザー又は第三者が被ったいかなる不利益又は損害についても、一切の責任を負わないものとします。</Text>
                <Text>●本サービスにかかるコンピュータシステムの保守点検又は更新を行う場合</Text>
                <Text>●地震、落雷、火災、停電、天災又はウィルスの蔓延などの不可抗力により、本サービスの提供が困難となった場合</Text>
                <Text>●コンピューター若しくは通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング、その他予期せぬ要因により本サービスの提供が困難となった場合</Text>
                <Text>●その他、当社が本サービスの提供が困難と判断した場合</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>保証の否認</Text>
                <Text>当社は、本サービスや本サービスが提供するコンテンツに、システムバグや第三者の権利侵害が含まれないことを保証するものではありません。また、安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性を保証するものでもありません。当社は、明示又は黙示を問わず、本サービスを通じて提供されるコンテンツその他の当社以外の第三者が提供する情報に関して何ら保証するものではありません。当社は、ユーザーが本サービスにアップロードするデータが、本サービスの利用に起因して消失、消滅、変化等しないことについて保証を行うものではありません。ユーザーは、自己の責任において、データの管理をし、適宜バックアップ等を行ってから本サービスを利用するものとします。当社は、本サービスと連携する外部サービスの提供者である第三者が提供するサービス、情報、個人情報の管理等について一切の責任を負いません。ユーザーは、自己の責任において、外部サービスを利用するものとします。
                ユーザーは、本サービスの利用に関連して第三者との間で生じた取引、連絡、紛争等については、自己の費用負担と責任において対応及び解決するものとします。
                </Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>免責</Text>
                <Text>当社は、本サービスに関してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
                消費者契約に該当する場合であっても、当社は、当社の過失（重過失を除きます。）によってユーザーに生じた損害のうち特別の事情から生じた損害（当社又はユーザーが損害発生につき予見し、又は予見し得た場合を含みます。）について、一切の責任を負いません。
                ユーザーと他のユーザー又は第三者との間において生じたトラブルについても一切責任を負いません。当社は、本サービスに関してユーザーが被った損害につき、当該損害が発生した月内にユーザーが当社に支払った利用料金を超えて賠償する責任を負わないものとします。
                </Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>サービス内容の変更</Text>
                <Text>当社は、ユーザーに通知することなく、本サービスの内容を変更したり、本サービスの提供を中止、終了することができるものとします。当社は、これによってユーザーに生じた損害について一切の責任を負いません。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>利用規約の変更</Text>
                <Text>当社は、ユーザーに通知することなく、いつでも本規約を変更することができるものとします。変更後の本規約は、当社ウェブサイトに掲示された時点から効力を生じるものとします。本規約の変更後、本サービスの利用を継続したユーザーは、変更後の本規約に同意したものとみなします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>個人情報の取扱い</Text>
                <Text>本サービスの利用によって取得するユーザーの個人情報については、当社のプライバシーポリシーに従い適切に取り扱うものとします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>通知又は連絡</Text>
                <Text>ユーザーと当社との間の通知又は連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知又は連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>権利義務の譲渡</Text>
                <Text>ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務を第三者に譲渡し、又は担保に供することはできません。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>事業譲渡</Text>
                <Text>当社は本サービスにかかる事業を他社に事業譲渡（事業譲渡、会社分割その他事業が移転するあらゆる場合を含みます。）した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの情報を当該事業譲渡の譲受人に譲渡することができるものとします。ユーザーは、かかる譲渡につき予め同意したものとみなします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>適用関係</Text>
                <Text>本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。当社は本サービスに関し、本規約のほか、ご利用にあたってのルールを定めることがあります。これらのルールは、その名称のいかんに関わらず、本規約の一部を構成するものとします。本規約がこれらのルールと矛盾する場合には、これらのルールが優先して適用されるものとします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>分離可能性</Text>
                <Text>本規約のいずれかの条項又はその一部が無効又は執行不能と判断された場合であっても、当該判断は他の部分に影響を及ぼさず、本規約の残りの部分は、引き続き有効かつ執行力を有するものとします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>準拠法・裁判管轄</Text>
                <Text>本規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する地方裁判所を専属的合意管轄とします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text>2023年11月1日制定</Text>
            </VStack>
        </VStack>
      </>
    )
}
export default Terms;