import {VStack,Text, Box} from '@chakra-ui/react'

const ButtonDownload = ({href,name,text}) => {
    return(
        <Box w={{ base:"100%", md:300 }}>
            <a href={href} download={name} target="_blank" rel="noreferrer">
                <VStack
                bg="black"
                px={4}
                py={3}
                color="white"
                fontSize={{base:14, md:16}}
                borderRadius={{ base:8, md:10, lg:8 }}
                alignItems="center"
                lineHeight={1.7}
                w={{ base:"100%", md:300 }}
                minW={{ base:"100%", md:300 }}
                h={{base:12,md:14}}
                >
                    <Text>{text}</Text>
                </VStack>
            </a>
        </Box>
    )
}
export default ButtonDownload;