import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider} from '@chakra-ui/react';
import App from './App';
import theme from './Theme';
import "./App.css"
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <ChakraProvider theme={theme}>
      <App/>
    </ChakraProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
