import {Text,HStack,VStack,Box} from '@chakra-ui/react'
import ButtonNormal from './page/parts/ButtonNormal';

const Page404 = () => {
    return(
        <>
      <HStack flexWrap="wrap">
        <VStack>
            <Box h={20}></Box>
            <VStack
            w='100vw'
            px={{base:"7%", md:"10%", lg:"15%", xl:"17.5%", '2xl':"25%"}}
            py={20}
            alignItems="flex-start"
            gap={4}
            >
                <Text>ページが見つかりません…</Text>
                <ButtonNormal to={"/"} text={"ホームへ"}/>
            </VStack>
        </VStack>
      </HStack>
      </>
    )
}
export default Page404;