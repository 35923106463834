import {Box, Heading,VStack, Text} from '@chakra-ui/react'

const Privacy = () => {
    return(
        <>
        <Box h={28}></Box>
        <VStack
        w='100vw'
        spacing={8}
        alignItems="flex-start"
        px={{base:"7%", md:"10%", lg:"15%", xl:"17.5%", '2xl':"25%"}}>
            <Heading as="h2" variant='lg'>プライバシーポリシー</Heading>
            <VStack alignItems="flex-start">
                <Text as='b'>個人情報の利用目的</Text>
                <Text>当サイトでは、お問い合わせ・アンケートへの回答・経験談投稿の際、名前・生年月日・性別・メールアドレス・SNSなどのアカウントID等の個人情報を入力いただく場合がございます。 取得した個人情報は、アンケート結果の分析に必要な分類基準として年齢又は性別を利用する場合を除き、お問い合わせに対する回答や必要な情報を電子メールなどでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>個人情報の管理と保護</Text>
                <Text>個人情報の管理は、厳重に行うこととし、法令に基づき認められている場合を除き、利用者の同意がない限り、第三者に対しデータを開示・提供することはいたしません。また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>個人情報の取扱いの委託</Text>
                <Text>当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>安全管理措置</Text>
                <Text>当社が利用者よりお預かりした個人情報は、個人情報ファイルへのアクセス制限の実施、アクセスログの記録及び外部からの不正アクセス防止のためのセキュリティ対策の実施等、組織的、物理的、人的、技術的施策を講じることで個人情報への不正な侵入、個人情報の紛失、破壊、改ざん、及び漏えい等を防止いたします。万一、利用者の個人情報の漏えい等の事故が発生した場合、当社は、個人情報保護法及び関連するガイドラインに則り、速やかに監督官庁への報告を行うとともに、当該監督官庁の指示に従い、類似事案の発生防止措置及び再発防止措置等の必要な対応を行います。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>個人情報の開示・訂正・利用停止等</Text>
                <Text>ご本人から個人情報の内容の開示（個人データの第三者提供記録の開示を含みます。）・訂正・利用停止・消去又は第三者提供の停止の請求があった場合は、個人情報保護法に基づき適切・迅速に対応いたします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>アンケートフォームについて</Text>
                <Text>当社は、アンケート調査のシステムとして「Googleフォーム」を利用しています。ユーザーが「Google フォーム」で入力した個人情報について、Googleは、独自のプライバシーポリシーに基づき保有、管理します。当社は、Googleが保有、管理する個人情報について責任を負いません。詳しくは、Google社の利用規約やプライバシーポリシーを確認いただきますようお願いいたします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>アクセス解析ツールについて</Text>
                <Text>当ブログでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>免責事項</Text>
                <Text>当ブログからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。また当ブログのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。【</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>著作権について</Text>
                <Text>当ブログで掲載している文章や画像などにつきましては、無断転載することを禁止します。当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、メールよりご連絡ください。迅速に対応いたします。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>リンクについて</Text>
                <Text>当ブログは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>当サイトへの投稿について</Text>
                <Text>次の各号に掲げる内容を含む投稿は管理人の裁量によって承認せず、削除する事があります。</Text>
                <Text>・特定の自然人または法人を誹謗し、中傷するもの。</Text>
                <Text>・極度にわいせつな内容を含むもの。</Text>
                <Text>・その他、公序良俗に反し、または管理人によって承認すべきでないと認められるもの</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>当社住所・代表者氏名・個人情報保護管理者</Text>
                <Text>当社住所、代表者および個人情報保護管理者の氏名は以下のとおりです。</Text>
                <Text>個人情報保護管理者：奥村春香</Text>
            </VStack>
            <VStack alignItems="flex-start">
                <Text as='b'>お問い合わせ窓口</Text>
                <Text>当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。</Text>
                <Text>お客様対応窓口</Text>
                <Text>Mail: info@daisan-kazoku.com</Text>
            </VStack>
        </VStack>
      </>
    )
}
export default Privacy;