import { Box, HStack, VStack } from '@chakra-ui/react'
import Main from './parts/Main';
const Home = () => {


  return (
    <>
      <Box h={20}></Box>
      <HStack flexWrap="wrap">
        <VStack>
          <Main />
        </VStack>
      </HStack>
    </>
  );
};

export default Home;
