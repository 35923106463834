import { extendTheme } from "@chakra-ui/react";
import { headingTheme } from "./Theme/HeadingTheme";
import './index.css'

const theme = extendTheme({
    components: {
        Heading: headingTheme
    },
    colors: {
        main: '#1EBFA2',
        black: '#000000',
        white: '#fff',
        line: '#D3D3D3',
        bg: '#FFFFFF',
        link: '#0094FF',
        mgray:{
            10: '#F5F5F5',
        },
        fgray: {
            100: '#727272',
            80: '#A3A3A3',
        },
        glight: '#d9f5f0',
    },
    fonts:{
        heading:`"LINESeedJP", sans-serif`,
        body:`"LINESeedJP", sans-serif`,
    },
    styles: {
        global: {
            html:{
                mx: "auto",
                bg: '#fff',
            },
        },
    },
});

export default theme;