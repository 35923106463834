import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"
const brandPrimary = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    color: "black",
})

const xl4 = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.3',
    fontSize:"4xl",
    letterSpacing:"0.1rem"
})

const xl3 = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.3',
    fontSize: "3xl",
    letterSpacing:"0.1rem"
})

const xl2 = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.3',
    fontSize: "2xl",
    letterSpacing:"0.1rem"
})

const xl = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.3',
    fontSize: "xl",
    letterSpacing:"0.1rem"
})

const lg = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.3',
    fontSize: "lg",
    letterSpacing:"0.11rem"
})

const md = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.4',
    fontSize: "md",
    letterSpacing:"0.11rem"
})

const xs = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.4',
    fontSize: "xs",
    letterSpacing:"0.11rem"
})

const sm = defineStyle({
    fontFamily:`"LINESeedJP", sans-serif`,
    lineHeight: '1.4',
    fontSize: "sm",
    letterSpacing:"0.11rem"
})

export const headingTheme = defineStyleConfig({
    variants: {
        brand: brandPrimary,
        "xl4": xl4,
        "xl3": xl3,
        "xl2": xl2,
        "xl": xl,
        "lg": lg,
        "md": md,
        "xs": xs,
        "sm": sm,
    },
})