import React from "react";
import {VStack,Heading,Text,Image} from "@chakra-ui/react";
import {modalImage,modalHeading,modalButton,modalAbout,modalTxt,modalHeadingTxtImage,modalHeadingTxtSns,modalHeadingTxtImageButton,modalLink,modalTitleBlock,modalCaption,modalLine,modalTable2,modalTable2Single,modalTable3,modalTable4,modalTable5,modalTable4Single,
  modalResearchAbout,modalTarget,modalTag,modalSmall,modalForCompany} from "./Main";
import hakusho2023  from "../file/hakusho2023.pdf";
import daisan_ticket from "../file/daisan_ticket.pdf";
import ButtonDownload from "../parts/ButtonDownload";

const y2023 = [
  { data:"about", image:'/img/about.png'},
  { data:"cake", image:'/img/2023/cake.png'},
  { data:"pot", image:'/img/2023/pot.png'},
  { data:"jenga", image:'/img/2023/jenga.png'},
  { data:"car", image:'/img/2023/car.png'},
  { data:"signal", image:'/img/2023/signal.png'},
  { data:"morning-glory", image:'/img/2023/morning-glory.png'},
  { data:"sign", image:'/img/2023/sign.png'},
  { data:"card", image:'/img/2023/card.png'},
  { data:"trump", image:'/img/2023/trump.png'},
  { data:"event", image:'/img/2023/event.png'},
  { data:"for-child", image:'/img/for-child.png'},
  { data:"cheer", image:'/img/cheer.png'},
  { data:"download", image:'/img/download.png'},
];

  const modal23 = (data) =>{
    switch (data) {
      case 'about':
        return(
          <>
            {modalTitleBlock(
              "家庭環境問題を「視覚比喩」で表現し、",
              "当事者が、傷ついていることに気づく。",
              "社会が、立ち止まって考えてみる。",
              "自分の家庭が居場所になっていない人は4人に1人。実は多いのですが、本人も社会もまだそれに気づけていないのではないでしょうか？",
              "本調査では、全国15歳〜25歳の400人を対象に調査を行いました。対象者の中の「家庭を自分の居場所だと思う群」「家庭を自分の居場所だと思えていない群」で調査結果の比較を行います。",
              "データの表現手法としては「視覚比喩」を用います。例えば、「死にたくなる」という事象を「沸騰して溢れそうな鍋」で表現します。このように、「ちょっと考えさせられる表現」にすることで、当事者が傷ついていることに気づいたり、社会が立ち止まって考えたりするきっかけづくりを目指します。"
              )}
            {modalLine()}
            {modalAbout(
              "調査主体",
              "NPO法人第3の家族",
              "家庭環境に悩むはざまの少年少女が「自分の居場所は他にもある」と思えるような「寄り添うための寄り添わない支援」を行います。それぞれの状況や気持ちに合ったサービスを展開し、自分の居場所を見つけることができる「状態」を遠くから構築します。")}
            {modalLine()}
            {modalResearchAbout(
              "調査概要",
              "調査手法 : Webアンケート",
              "調査地域 : 全国",
              "調査対象 : 全国15歳以上25歳以下の男女",
              "調査期間 : 2023/10/7〜2023/10/13",
              "有効回答数 : 400サンプル",
            )}
            {modalLine()}
            {modalTarget()}
            {modalLine()}
            {modalResearchAbout(
              "Web版家庭環境データについて",
              "Web版家庭環境データでは、「独自アンケート調査」の他にも、既に国や他の団体の調査を参照した「既存データより引用」したものや、過去に行った「独自インタビュー調査」も含みます。各ページにカテゴリーを記載しています。",
            )}
          </>
        )
      case 'cake':
        return(
          <>
            {modalImage("/img/2023/cake.png","一つだけデコレーションがないケーキ" )}
            {modalCaption(
              "実は身近にいる",
              "家庭を自分の居場所だと感じられていない人は4人に1人。30人のクラスなら8人くらいが悩んでいるかもしれません。意外と多いのではないでしょうか。",
              "ひとつのケーキだけデコレーションがない。"
              )}
            {modalTag("独自アンケート調査")}
            {modalTable4Single(
              "家庭が今のあなたにとって居場所（ほっとできる場所、居心地の良い場所など）になっていますか。",
              "回答" ,"15歳以上25歳以下",
              "そう思う","46.2%",
              "どちらかといえばそう思う","30.1%",
              "どちらかといえばそう思わない","10.7%",
              "そう思わない","13.1%"
              )}
            {modalLink("内閣府 (2023)「令和4年版 子供・若者白書」の「2.周囲について①居場所」と同様の質問を行いました。",'https://www8.cao.go.jp/youth/suisin/hakusho.html')}
          </>
        )
      case 'pot':
        return(
          <>
            {modalImage("/img/2023/pot.png","溢れそうなお鍋" )}
            {modalCaption(
              "死にたくなってしまう",
              "家庭に問題があると、死にたい気持ち（希死念慮）が強くなってしまいます。自殺を踏みとどまる理由の一つに親の存在があると言われますが、そういった存在が不足している少年少女はふとしたことで感情が溢れ出てしまいやすいのではないでしょうか。",
              "悲しさや苦しみが溢れ出しそう。"
              )}
            {modalTag("既存データより引用")}
            {modalTable2(
              "あなたはこれまでに死ねたらと本気で思った、または自死の可能性を本気で考えたことがありますか。",
              "回答" ,"希死念慮経験あり","希死念慮経験なし",
              "家庭問題の経験あり","73.6%","26.4%",
              "家庭問題の経験なし","33.2%","66.8%",
              "「あなたはこれまでに死ねたらと本気で思った、または自死の可能性を本気で考えたことがありますか。ある方は、そう思ったのはいつですか。」との設問に、「小学校入学前」「小学生」「中学生」「高校生(16～18 歳になる年)」「大学生・専門学校生(19～22 歳になる年)」「23 歳以上」「時期は覚えていないが、ほとんどいつもこの状況である」「時期は覚えていないが、この状況になったことがある」を選択した者を「希死念慮経験あり」、「いずれもない」を選択した者を「希死念慮経験なし」と分類している。"
              )}
            {modalLink("参考：日本財団 (2023)「第5回自殺意識全国調査 調査結果」",'https://www.nippon-foundation.or.jp/who/news/pr/2023/20230406-87204.html')}
          </>
        )
      case 'jenga':
        return(
          <>
            {modalImage("/img/2023/jenga.png","崩れそうなジェンガ" )}
            {modalCaption(
              "心の病気を発症しやすい",
              "日常的に言われる心無い言葉・過干渉・無視・放任などから、将来心の病気を発生しやすくなります。骨折などの身体的な病気と比べて、じわじわと傷が増えていくので、本人も傷ついていることに気づいておらず、大人になってから生きづらさを抱えることも多いです。",
              "土台の父・母の2本が欠けていて倒れやすい。"
              )}
            {modalTag("独自アンケート調査")}
            {modalTable3(
              "気分障害（うつ・躁うつ）の経験がありますか",
              "回答" ,"居場所である","居場所でない",
              "ある","8.0%","9.0%",
              "ある気がする","15.0%","23.5%",
              "ない","77.0%","67.5%",
              "「医師から気分障害と診断された経験がある」と答えた人を「ある」、「診断された経験はないが憂鬱な気持ちや気分の低下があり、気分障害がある気がする。またはそのような時期があったような気がする。」と答えた人を「ある気がする」、「気分障害の経験はない」と答えた人を「ない」と表記。")}
          </>
        )
      case 'car':
        return(
          <>
            {modalImage("/img/2023/car.png","歪んだ人生ゲームのピン" )}
            {modalCaption(
              "結婚に億劫になる",
              "幸せな家庭を知らないことや、自分も同じようなことをしてしまうのではないかという恐れから、結婚に億劫になることもあります。少子化対策には、親への支援と同時に子への支援も必要なのではないでしょうか。",
              "人生ゲームのピンが歪んでしまっている。"
              )}
            {modalTag("独自アンケート調査")}
            {modalTable4(
              "自分の一生を通じて考えた場合、あなたの結婚に対する考えは、次のうちどちらですか。",
              "回答" ,"居場所である","居場所でない",
              "いずれ結婚するつもり","77.0%","47.0%",
              "一生結婚するつもりはない","15.0%","22.5%",
              "現在は結婚している","6.0%","7.5%",
              "離婚または死別して現在は独身","2.0%","23.0%",
              "未婚者のみに質問。")}
            {modalSmall("「結婚に億劫になりやすい」という仮説のもとに調査を行ったが、離婚・死別の経験者が最も多かった。よって、早期の結婚・離婚のケースが多いことが見込まれる。さらに詳しい実態について、今後調査をする必要がある。")}
          </>
        )
      case 'signal':
        return(
          <>
            {modalImage("/img/2023/signal.png","黄色信号" )}
            {modalCaption(
              "グレーに足を踏み入れる",
              "そうせざるをえない、リスクを知らない、愛情を受けていない、などといった理由から、グレーなことに足を踏み入れやすくなります。問題ではありますが、彼ら・彼女らだけの責任でしょうか。",
              "赤信号になる前。"
              )}
            {modalTag("独自アンケート調査")}
            {modalTable2(
              "次のうち、自分が経験のあるもの全てに選択をしてください。",
              "回答" ,"居場所である","居場所でない",
              "家出","6.0%","7.5%",
              "パパ活やママ活","1.0%","5.5%",
              "この質問の回答により、第三者に個人を特定して通告することはない。")}
              {modalSmall("「いじめの加害者」「夜の街で遊ぶ」は「居場所である」方のポイントが高かった。また、家出に関しては大きな差異ではない。そこまでのことをする気力がないのか、そういった行為は固く禁じられているのか、今後調査をする必要がある。")}
          </>
        )
      case 'morning-glory':
        return(
          <>
            {modalImage("/img/2023/morning-glory.png","朝顔のプランター" )}
            {modalCaption(
              "他の困っている人を助けてあげられる",
              "自分のつらかった経験から、非営利活動に貢献したり、福祉の仕事に従事したりする人がいます。",
              "いろんなパーツが少しずつ朝顔を支え合っている。"
              )}
            {modalTag("独自インタビュー調査")}
            {modalSmall("「他の困っている人を助けてあげられる」という仮説のもとに調査を行ったが、全ての項目において「居場所でない」の方が低い結果となった。そこまでする時間がないのか、気力がないのか、情報が届いていないのか、今後調査をする必要がある。今回は、第3の家族のスタッフや数名の精神保健福祉士へのインタビューに基づいた、個別のケースの結果である。")}
            {modalTable5(
              "次のうち、経験したことがあること全てに選択をしてください。",
              "回答" ,"居場所である","居場所でない",
              "自主的に寄付をしたことがある","41.5%","23.0%",
              "学校の授業や課題以外でボランティアをしたことがある","36.5%","19.0%",
              "悩んでいる友達がいた時に話を聞いたり励ましたりしたことがある","52.0%","27.5%",
              "街中、駅などで困っている人を見かけて、助けたことがある","31.5%","17.0%",
              "福祉の仕事に興味がある","12.5%","7.5%",
              "")}
          </>
        )
      case 'sign':
        return(
          <>
            {modalImage("/img/2023/sign.png","スリップ標識")}
            {modalCaption(
              "昔はいろいろあったけど、今は幸せも感じている",
              "「家庭環境が悪かったから人生終わり」とも言いきれません。新たな自分の居場所を見つけている人たちもいます。",
              "スリップの標識も、事故は起こしていない。"
              )}
            {modalTag("独自インタビュー調査")}
            {modalButton(
              "87名が新たに見つけた居場所",
              "第3の家族のサービスnigerunoでは、家庭環境に悩んだ人の経験談を掲載しています。皆さん大変な過去がありますが、それぞれの人生を見つけています。たどり着く居場所は、パートナーでも職業でも自分自身でもよいでしょう。自分の居心地が良い場所が家庭の他にもある状態がまずは一歩でしょう。",
              "https://nigeruno.gedokun.com/ex.html",
              "nigerunoへ"
              )}
          </>
        )
        case 'card':
          return(
            <>
              {modalImage("/img/2023/card.png","リバースカード")}
              {modalCaption(
                "負の連鎖を断ち切ろうという意志",
                "「親からそうされてきたから自分も子にそうする」といったように、家庭環境は負の連鎖を引き起こしやすいです。しかし、今の時代はそれを断ち切ろうという流れが生まれているのかもしれません。SNSで他人の生活を知りやすくなったことも一つの要因でしょう。",
                "流れを変えるリバースカード。"
                )}
              {modalTag("独自アンケート調査")}
              {modalTable4Single(
                "もし自分が子育てをしたとき、あるいは現在子育てをしているとき「自分が子どものころ経験したような家庭環境を繰り返さないようにしたい」と思いますか。",
                "回答" ,"家庭を自分の居場所と感じられていない人",
                "そう思う","35.0%",
                "どちらかといえばそう思う","20.0%",
                "どちらかといえばそう思わない","18.5%",
                "そう思わない","26.5%"
                )}
                {modalSmall("「どちらかといえばそう思わない」が18.5%、「そう思わない」が、26.5%と高い数値ではある。なぜそう回答するのか、今度調査をする必要がある。")}
            </>
          )
        case 'trump':
          return(
            <>
              {modalImage("/img/2023/trump.png","トランプ")}
              {modalCaption(
                "周りから見たら「普通の子」",
                "「心配かけたくない」「引かれるかもしれない」「自分でなんとかしよう」そう思って、周りの人にも相談しないことが多いです。「家庭環境問題 = 貧乏で暴力を受けている子」という時代は終わりました。",
                "表は同じでも、裏には何が書いてあるだろう。"
                )}
              {modalTag("独自アンケート調査")}
              {modalTable2Single(
                "家庭環境の悩みを周りの人に知られたくないために、周囲の人に嘘をついたり隠したことはありますか？",
                "回答" ,"家庭を自分の居場所と感じられていない人",
                "はい","49.5%",
                "いいえ","50.5%",
                )}
            </>
          )
        case 'event':
          return(
            <>
              {modalHeadingTxtImageButton(
                "家庭環境データ展示",
                "家庭環境データの公開を記念して、展示を行います。よろしければお越しください。",
                "/img/2023/event1.png",
                "展示紹介画像",
                "https://100banch.com/kazoku1119",
                "展示詳細"
                )}
              {modalHeadingTxtImageButton(
                "トークイベント1：「支援」のハードルを下げるには",
                "展示と並行して、福祉業界の専門家のみなさんと対談を行います。自殺対策を行うNPO法人OVA代表の伊藤次郎さん、15〜25歳の若者支援を行う荒井佑介さん、お悩みハンドブックを制作する佐藤まみさん、代表の奥村で「<支援>のハードルを下げるには」というテーマで話し合います。",
                "/img/2023/talk-event1.png",
                "トークイベント1紹介画像",
                "http://ptix.at/wGpx0x",
                "イベント申し込み"
                )}
              {modalHeadingTxtImage(
                "トークイベント2：当事者裏座談会",
                "当事者&第3の家族メンバーの佐藤と奥村で、家庭環境のお悩みをテーマに裏座談会を行います。データには載せきれない当事者の本音やリアルな実態について話します。お時間のある方はよければご参加ください。申し込みは不要です。",
                "/img/2023/talk-event2.png",
                "トークイベント2紹介画像"
                )}
            </>
          )
        case 'for-child':
          return(
            <>
              {modalHeadingTxtImageButton(
                "自分の居場所は他にもある",
                "悲しいデータもありましたが、居場所というのは、必ずしも家族だけではないと思うのです。この調査を見て思い当たることがある人は、よければ第3の家族を覗いてみてください。",
                "/img/mv.png",
                "メインビジュアル",
                "https://daisan-kazoku.com/",
                "第3の家族公式HPへ"
                )}
              {modalHeadingTxtImageButton(
                "居場所を見つけるための情報収集",
                "情報サイトnigerunoでは、家庭環境に悩んだ人がどのようにして今の生活に辿り着いたかを紹介しています。国がやっている支援はもちろん、役に立つ最近のサービスなどもまとめています。",
                "/img/nigeruno.png",
                "nigerunoサービス画像",
                "https://nigeruno.gedokun.com/",
                "nigeruno"
                )}
              {modalHeadingTxtImageButton(
                "悩みを吐き出す掲示板",
                "匿名で会員登録も不要、返信機能もありません。まずは悩みを吐き出してみるのもいいかもしれません。共感のボタンで励まし合うことができます。",
                "/img/gedokun.png",
                "gedokunサービス画像",
                "https://gedokun.com//",
                "gedokun"
                )}
              {modalHeadingTxtImageButton(
                "同じ悩みで集まるちょっと変わった部活動",
                "母の日や父の日にみんなで集まる裏祝日部、家庭環境に悩む人で行うライブなど、ちょっと変わった部活動が集まっています。",
                "/img/bukatsu.png",
                "第3の部活サービス画像",
                "https://scrapbox.io/daisan/",
                "第3の部活"
                )}
              {modalHeadingTxtImageButton(
                "第3の家族Discord",
                "家庭環境に悩む人のためのイベント情報、最新情報をDiscordで発信していきます。悩みの大小は問わないので、どなたでもお気軽にご参加ください。いつでも退出は可能です。",
                "/img/discord.png",
                "Discordアイコン",
                "https://discord.gg/svKBVjjRKs",
                "第3の家族Discord"
                )}
              {modalHeadingTxtSns()}
            </>
          )
        case 'cheer':
          return(
            <>
              {modalHeadingTxtImageButton(
                "月額100円の寄付",
                "第3の家族では月額100円の寄付を募集しております。こちらは活動資金のためというよりは、この問題に関心を持つ人を増やしたい思いで行っております。不定期のメール配信と、毎年3月にはお礼の手紙を郵送（またはメール）しますので、よろしければお願いします。いつでも解約は可能です。",
                "/img/letter.png",
                "封筒",
                "https://buy.stripe.com/fZe9BV5Fdamq0JGcMO",
                "月額100円の寄付をする"
                )}
              {modalHeadingTxtImageButton(
                "年額3,000円の寄付",
                "第3の家族では年額3,000円の寄付を募集しております。寄付者様用のSlackチャンネルにご招待します。いつでも解約は可能です。",
                "/img/member.png",
                "メンバー写真",
                "https://buy.stripe.com/eVa8xRd7F3Y2csobIQ",
                "年額3,000円の寄付をする"
                )}
              {modalHeadingTxtImageButton(
                "第3の家族グッズ",
                "第3の家族のグッズもあります。第3の家族の中には数名のデザイナーがおり、それぞれのクリエイティブを担当しています。こちらの売り上げを活動費に利用しますので、よろしければご覧ください。",
                "/img/goods.png",
                "グッズ写真",
                "https://suzuri.jp/daisan_kazoku",
                "グッズ購入ページへ"
                )}
              <VStack spacing={4} alignItems="flex-start">
                  <VStack spacing={2} alignItems="flex-start">
                      <Heading as="h1" variant='xl'>サービス紹介チケット</Heading>
                      <Text lineHeight='1.7' fontSize='md'>第3の家族のサービスを紹介するチケットです。チケットやしおりのような見た目で、少年少女が手に取りたくなる・持ち続けたくなるデザインを心がけています。</Text>
                  </VStack>
                  <Image src="/img/ticket.png" alt="画像" w="100%"/>
                  <ButtonDownload href={daisan_ticket} name="daisan_ticket" text="ダウンロード"/>
              </VStack>
              {modalForCompany()}
              {modalHeadingTxtImageButton(
                "スタッフ募集",
                "スタッフも通年募集しております。当事者が多いですが、それ以外の方も歓迎です。それぞれのスタッフが自分の思いや強みを発揮して、ものづくりを行なっています。",
                "/img/portfolio.png",
                "スタッフポートフォリオ",
                "https://docs.google.com/forms/d/e/1FAIpQLSeEoQNEN6EpBZTUhlrSDQJrBMe4sCysIiVnEg8qJaupTBtf_g/viewform?usp=sf_link",
                "スタッフ募集へ"
                )}
                {modalHeadingTxtSns()}
            </>
          )
        case 'download':
          return(
            <>
              <VStack spacing={4} alignItems="flex-start">
                  <VStack spacing={2} alignItems="flex-start">
                      <Heading as="h1" variant='xl'>白書データ</Heading>
                      <Text lineHeight='1.7' fontSize='md'>本調査をまとめた資料です。Web版には載せていない項目も掲載しております。ご自由にお使いください。</Text>
                  </VStack>
                  <Image src="/img/hakusho.png" alt="画像" w="100%"/>
                  <ButtonDownload href={hakusho2023} name="hakusho2023" text="ダウンロード"/>
              </VStack>
              <VStack spacing={4} alignItems="flex-start">
                  <VStack spacing={2} alignItems="flex-start">
                      <Heading as="h1" variant='xl'>サービス紹介チケット</Heading>
                      <Text lineHeight='1.7' fontSize='md'>第3の家族のサービスを紹介するチケットです。チケットやしおりのような見た目で、少年少女が手に取りたくなる・持ち続けたくなるデザインを心がけています。</Text>
                  </VStack>
                  <Image src="/img/ticket.png" alt="画像" w="100%"/>
                  <ButtonDownload href={daisan_ticket} name="daisan_ticket" text="ダウンロード"/>
              </VStack>
          </>
          )

      default:
          return(
              <>
                {modalHeading("記事の投稿までしばらくお待ちください。")}
                {modalTxt("ただいま文章を作成中です。")}
              </>
          )
    }
  }

  export {y2023,modal23};