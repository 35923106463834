import {VStack,Text,Link} from '@chakra-ui/react'

const ButtonEx = ({href,text}) => {
    return(
        <Link href={href} isExternal  w={{ base:"100%", md:300 }}>
            <VStack
            bg="black"
            px={4}
            py={3}
            color="white"
            fontSize={{base:14, md:16}}
            borderRadius={{ base:8, md:10, lg:8 }}
            lineHeight={1.7}
            h={{base:12,md:14}}
            w={{ base:"100%", md:300 }}
            justifyContent="center"
            >
                <Text>{text}</Text>
            </VStack>
        </Link>
    )
}
export default ButtonEx;