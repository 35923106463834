import React from "react";
import {Button,VStack,HStack,Heading,Text,Image,SimpleGrid,Modal,ModalOverlay,ModalContent,ModalCloseButton,ModalBody,useDisclosure,Link, Box,Table,Thead,Tbody,Tr,Th,Td,TableContainer,} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { y2023,modal23 } from "./2023";
import ButtonEx from "./ButtonEx";
import Sns from "./Sns";



const modalImage = (img,alt) => {
  return(
    <Image src={img} alt={alt} w="100%"/>
  )
}
const modalHeading = (txt) => {
  return(
    <Heading as="h1" variant='xl'>{txt}</Heading>
  )
}
const modalTxt = (txt) => {
  return(
    <Text lineHeight='1.7' fontSize='md'>{txt}</Text>
  )
}
const modalHeadingTxt = (title,txt) => {
    return(
        <VStack spacing={2} alignItems="flex-start">
            <Heading as="h1" variant='xl'>{title}</Heading>
            <Text lineHeight='1.7' fontSize='md'>{txt}</Text>
        </VStack>
    )
  }
const modalHeadingTxtImage = (title,txt,img,alt) => {
    return(
        <VStack spacing={4} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start">
                <Heading as="h1" variant='xl'>{title}</Heading>
                <Text lineHeight='1.7' fontSize='md'>{txt}</Text>
            </VStack>
            <Image src={img} alt={alt} w="100%"/>
        </VStack>
    )
  }
const modalHeadingTxtImageButton = (title,txt,img,alt,to,label) => {
    return(
        <VStack spacing={4} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start">
                <Heading as="h1" variant='xl'>{title}</Heading>
                <Text lineHeight='1.7' fontSize='md'>{txt}</Text>
            </VStack>
            <Image src={img} alt= {alt} w="100%"/>
            <ButtonEx href={to} text={label}/>
        </VStack>
    )
  }
const modalHeadingTxtSns = () => {
    return(
        <VStack spacing={4} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start">
                <Heading as="h1" variant='xl'>第3の家族のSNS</Heading>
                <Text lineHeight='1.7' fontSize='md'>Xでは最新情報、YouTubeではお悩み相談配信、Instagramでは家庭環境データのグラフィック、TikTokでは短編動画を上げています。フォローお願いします！</Text>
            </VStack>
            <HStack spacing={8}>
                <Sns href={'https://twitter.com/daisan_kazoku'} src={'/img/x.png'} alt={"X"}/>
                <Sns href={'https://www.youtube.com/@daisan_kazoku'} src={'/img/YouTube.png'} alt={"YouTube"}/>
                <Sns href={'https://www.instagram.com/daisan_kazoku/'} src={'/img/Instagram.png'} alt={"Instagram"}/>
                <Sns href={'https://www.tiktok.com/@daisan_kazoku'} src={'/img/TikTok.png'} alt={"TikTok"}/>
            </HStack>
        </VStack>
    )
  }
const modalHeadingTxtButton = (title,txt,to,label) => {
    return(
        <VStack spacing={4} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start">
                <Heading as="h1" variant='xl'>{title}</Heading>
                <Text lineHeight='1.7' fontSize='md'>{txt}</Text>
            </VStack>
            <ButtonEx href={to} text={label}/>
        </VStack>
    )
  }
const modalTitleBlock = (title1,title2,title3,txt1,txt2,txt3) => {
  return(
    <VStack spacing={5} alignItems="flex-start">
        <VStack spacing={0} alignItems="flex-start" display={{base:"none", md:"block"}}>
            <Heading as="h1" variant={{base:'lg', lg:'xl'}}>{title1}</Heading>
            <Heading as="h1" variant={{base:'lg', lg:'xl'}}>{title2}</Heading>
            <Heading as="h1" variant={{base:'lg', lg:'xl'}}>{title3}</Heading>
        </VStack>
        <Heading as="h1" variant={{base:'lg', lg:'xl'}} display={{base:"block", md:"none"}}>
            {title1}{title2}{title3}
        </Heading>
        <VStack spacing={4}>
            <Text lineHeight='1.7' fontSize='md'>{txt1}</Text>
            <Text lineHeight='1.7' fontSize='md'>{txt2}</Text>
            <Text lineHeight='1.7' fontSize='md'>{txt3}</Text>
        </VStack>
    </VStack>
  )
  }
  const modalForCompany = (title,txt,img,to,label) => {
    return(
        <VStack spacing={4} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start">
                <Heading as="h1" variant='xl'>企業様からの寄付について</Heading>
                <Text lineHeight='1.7' fontSize='md'>
                    企業寄付もお待ちしております。活動資金が全く足りなくて困っています。少年少女のためのサービスをつくりたいため、サービスからお金をとることはできません。少しでも応援していただけると幸いです。よろしければメールよりお問い合わせください。
                    </Text>
            </VStack>
            <VStack p={4} bg="mgray.10" spacing={4} alignItems="flex-start" w="100%" borderRadius={12}>
                <VStack spacing={1} alignItems="flex-start">
                    <Heading as="h2" variant='sm'>寄付</Heading>
                    <Text>1口20万円</Text>
                </VStack>
                <VStack spacing={1} alignItems="flex-start">
                    <Heading as="h2" variant='sm'>リターン内容</Heading>
                    <Text>社員研修・講演</Text>
                </VStack>
                <VStack spacing={1} alignItems="flex-start">
                    <Heading as="h2" variant='sm'>ご寄付方法</Heading>
                    <Text>お問い合わせ後、希望のリターン（受け取りは必須ではございません）についてご確認いたします。確認後、入金をお願いします。</Text>
                </VStack>
            </VStack>
            <ButtonEx href="mailto:info@daisan-kazoku.com" text="お問い合わせ"/>
        </VStack>
    )
  }
const modalAbout = (title,txt1,txt2) => {
  return(
    <VStack spacing={2} alignItems="flex-start">
        <Heading as="h2" variant='sm'>{title}</Heading>
        <VStack spacing={1} alignItems="flex-start">
            <Text lineHeight='1.7' fontSize='md'>{txt1}</Text>
            <Text lineHeight='1.7' fontSize='sm' color="fgray.100">{txt2}</Text>
        </VStack>
        <Link href="https://daisan-kazoku.com/" isExternal color='fgray.100' fontSize={9}>NPO法人第3の家族公式HP<ExternalLinkIcon mx='2px' /></Link>
    </VStack>
  )
}
const modalResearchAbout = (title,txt1,txt2,txt3,txt4,txt5,txt6,txt7) => {
    return(
        <VStack spacing={2} alignItems="flex-start">
            <Heading as="h2" variant='sm'>{title}</Heading>
            <VStack spacing={0} alignItems="flex-start">
                <Text lineHeight='1.7' fontSize='sm'>{txt1}</Text>
                <Text lineHeight='1.7' fontSize='sm'>{txt2}</Text>
                <Text lineHeight='1.7' fontSize='sm'>{txt3}</Text>
                <Text lineHeight='1.7' fontSize='sm'>{txt4}</Text>
                <Text lineHeight='1.7' fontSize='sm'>{txt5}</Text>
                <Text lineHeight='1.7' fontSize='sm'>{txt6}</Text>
                <Text lineHeight='1.7' fontSize='sm'>{txt7}</Text>
            </VStack>
        </VStack>
    )
  }
const modalCaption = (title,txt1,txt2) => {
  return(
    <VStack spacing={3} alignItems="flex-start">
        <VStack spacing={2} alignItems="flex-start">
            <Heading as="h1" variant='xl'>{title}</Heading>
            <Text lineHeight='1.7' fontSize='md'>{txt1}</Text>
        </VStack>
        <Text lineHeight='1.7' color='fgray.100' fontSize='xs'>{txt2}</Text>
    </VStack>
  )
}
const modalTag = (txt) => {
  return(
    <Box py={4} w="100%" borderY="1px solid #D3D3D3" fontSize={14}>
        {txt}
    </Box>
  )
}
const modalSmall = (txt) => {
  return(
    <Text lineHeight='1.7' color='fgray.100' fontSize='xs'>{txt}</Text>
  )
}
const modalLine = () => {
  return(
    <Box h="1px" w="100%" borderTop="1px solid #D3D3D3" ></Box>
  )
}
const modalLink = (txt,link) => {
  return(
    <Link href={link} isExternal color='fgray.100' fontSize={9}>{txt}<ExternalLinkIcon mx='2px' /></Link>
  )
}

const modalTable5 = (txt,th1,th2,th3,td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12,td13,td14,td15,discription) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={0}>
                <Heading as="h2" variant='sm'>質問</Heading>
                <Text fontSize={14} >{txt}</Text>
             </VStack>
            <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                <Table size='sm' variant="unstyled">
                    <Thead>
                    <Tr>
                        <Th>{th1}</Th>
                        <Th>{th2}</Th>
                        <Th>{th3}</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>{td1}</Td>
                        <Td isNumeric>{td2}</Td>
                        <Td isNumeric>{td3}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td4}</Td>
                        <Td isNumeric>{td5}</Td>
                        <Td isNumeric>{td6}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td7}</Td>
                        <Td isNumeric>{td8}</Td>
                        <Td isNumeric>{td9}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td10}</Td>
                        <Td isNumeric>{td11}</Td>
                        <Td isNumeric>{td12}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td13}</Td>
                        <Td isNumeric>{td14}</Td>
                        <Td isNumeric>{td15}</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text spacing={0} color="fgray.100" fontSize={12} alignItems="left">
                {discription}
            </Text>
        </VStack>
    )
  }
const modalTable4 = (txt,th1,th2,th3,td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12,discription) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={0}>
                <Heading as="h2" variant='sm'>質問</Heading>
                <Text fontSize={14} >{txt}</Text>
             </VStack>
            <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                <Table size='sm' variant="unstyled">
                    <Thead>
                    <Tr>
                        <Th>{th1}</Th>
                        <Th>{th2}</Th>
                        <Th>{th3}</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>{td1}</Td>
                        <Td isNumeric>{td2}</Td>
                        <Td isNumeric>{td3}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td4}</Td>
                        <Td isNumeric>{td5}</Td>
                        <Td isNumeric>{td6}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td7}</Td>
                        <Td isNumeric>{td8}</Td>
                        <Td isNumeric>{td9}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td10}</Td>
                        <Td isNumeric>{td11}</Td>
                        <Td isNumeric>{td12}</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text color="fgray.100" fontSize={12} >
                {discription}
            </Text>
        </VStack>
    )
  }
const modalTable4Single = (txt,th1,th2,td1,td2,td4,td5,td7,td8,td10,td11) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={0}>
                <Heading as="h2" variant='sm'>質問</Heading>
                <Text fontSize={14} >{txt}</Text>
             </VStack>
            <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                <Table size='sm' variant="unstyled">
                    <Thead>
                    <Tr>
                        <Th>{th1}</Th>
                        <Th>{th2}</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>{td1}</Td>
                        <Td isNumeric>{td2}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td4}</Td>
                        <Td isNumeric>{td5}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td7}</Td>
                        <Td isNumeric>{td8}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td10}</Td>
                        <Td isNumeric>{td11}</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    )
  }
const modalTable3 = (txt,th1,th2,th3,td1,td2,td3,td4,td5,td6,td7,td8,td9,discription) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={0}>
                <Heading as="h2" variant='sm'>質問</Heading>
                <Text fontSize={14} >{txt}</Text>
             </VStack>
            <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                <Table size='sm' variant="unstyled">
                    <Thead>
                    <Tr>
                        <Th>{th1}</Th>
                        <Th>{th2}</Th>
                        <Th>{th3}</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>{td1}</Td>
                        <Td isNumeric>{td2}</Td>
                        <Td isNumeric>{td3}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td4}</Td>
                        <Td isNumeric>{td5}</Td>
                        <Td isNumeric>{td6}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td7}</Td>
                        <Td isNumeric>{td8}</Td>
                        <Td isNumeric>{td9}</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text spacing={0} color="fgray.100" fontSize={12} alignItems="left">
                {discription}
            </Text>
        </VStack>
    )
  }
const modalTable2 = (txt,th1,th2,th3,td1,td2,td3,td4,td5,td6,discription) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={0}>
                <Heading as="h2" variant='sm'>質問</Heading>
                <Text fontSize={14} >{txt}</Text>
             </VStack>
            <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                <Table size='sm' variant="unstyled">
                    <Thead>
                    <Tr>
                        <Th>{th1}</Th>
                        <Th>{th2}</Th>
                        <Th>{th3}</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>{td1}</Td>
                        <Td isNumeric>{td2}</Td>
                        <Td isNumeric>{td3}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td4}</Td>
                        <Td isNumeric>{td5}</Td>
                        <Td isNumeric>{td6}</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text spacing={0} color="fgray.100" fontSize={12} alignItems="left">
                {discription}
            </Text>
        </VStack>
    )
  }
  const modalTable2Single = (txt,th1,th2,td1,td2,td4,td5) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={0}>
                <Heading as="h2" variant='sm'>質問</Heading>
                <Text fontSize={14} >{txt}</Text>
             </VStack>
            <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                <Table size='sm' variant="unstyled">
                    <Thead>
                    <Tr>
                        <Th>{th1}</Th>
                        <Th>{th2}</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>{td1}</Td>
                        <Td isNumeric>{td2}</Td>
                    </Tr>
                    <Tr>
                        <Td>{td4}</Td>
                        <Td isNumeric>{td5}</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    )
  }
const modalTable1 = (txt,th1,th2,th3,td1,td2,td3,discription) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={0}>
                <Heading as="h2" variant='sm'>質問</Heading>
                <Text fontSize={14} >{txt}</Text>
             </VStack>
            <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                <Table size='sm' variant="unstyled">
                    <Thead>
                    <Tr>
                        <Th>{th1}</Th>
                        <Th>{th2}</Th>
                        <Th>{th3}</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>{td1}</Td>
                        <Td isNumeric>{td2}</Td>
                        <Td isNumeric>{td3}</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text spacing={0} color="fgray.100" fontSize={12} alignItems="left">
                {discription}
            </Text>
        </VStack>
    )
  }
const modalButton = (title,txt,to,label) => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={3}>
             <VStack alignItems="left" spacing={2}>
                <Heading as="h2" variant='md'>{title}</Heading>
                <Text lineHeight='1.7' fontSize='md'>{txt}</Text>
             </VStack>
             <ButtonEx href={to} text={label}/>
        </VStack>
    )
  }
const modalTarget = () => {
    return(
        <VStack alignItems="left" minW="100%" maxW="100%" spacing={2}>
            <Heading as="h2" variant='sm'>調査対象</Heading>
            <VStack alignItems="left" spacing={4}>
                <Text fontSize={14}>「家庭が今のあなたにとって居場所（ほっとできる場所、居心地の良い場所など）になっていますか。」という質問に対して、「そう思う」「どちらかといえばそう思う」と答えた人を「居場所である」、「どちらかといえばそう思わない」「そう思わない」と答えた人を「居場所でない」に分類します。</Text>
                <TableContainer w="100%" bg="mgray.10" py={2.5} color="black" overflowX="scroll" whiteSpace="nowrap">
                    <Table size='sm' variant="unstyled">
                        <Thead>
                        <Tr>
                            <Th>回答結果</Th>
                            <Th>男性</Th>
                            <Th>女性</Th>
                            <Th>全体</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        <Tr>
                            <Th>居場所である</Th>
                            <Td isNumeric>72人</Td>
                            <Td isNumeric>128人</Td>
                            <Td isNumeric>200人</Td>
                        </Tr>
                        <Tr>
                            <Th>居場所でない</Th>
                            <Td isNumeric>74人</Td>
                            <Td isNumeric>126人</Td>
                            <Td isNumeric>200人</Td>
                        </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </VStack>
    )
  }



const NewsCard = ({data,image}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return(
    <Button onClick={onOpen} h="auto" bg="white"  _hover={{ bg: '#fff', opacity: "0.5"}}>
    <VStack key={data}  spacing={4}>
        <Image src={image} alt={data} w="100%" objectFit="contain"  _hover={{ w: "92%"}}/>
        <Modal isOpen={isOpen} onClose={onClose} size={{base:'sm', md:'md', lg:'xl'}} scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent py={4} borderRadius='8px'>
                <ModalCloseButton />
                <ModalBody>
                <VStack spacing={{base:10, md:14}} p={{base:2.5, md:4}} alignItems="flex-start">
                    {modal23(data)}
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    </VStack>
    </Button>
  )
}

const Grid = (year) => {
  return (
    <SimpleGrid spacing={{ base: 2, md: 6, lg: 10 }}  columns={{ base: 2, md: 2, lg: 3 }} 
    w='100vw'
    px={{base:"3%", md:"10%", lg:"15%", xl:"17.5%", '2xl':"25%"}}
    >
      {year.map((link) => (
        NewsCard(link)
      ))}
    </SimpleGrid>
  );
};

const Main = () => {
  return (
    <>
        <Box
            py={6}
        >
            {Grid(y2023)}
        </Box>
    </>
  );
};

export default Main;
export {modalImage,modalHeading,modalTxt,modalHeadingTxt,modalHeadingTxtImage,modalHeadingTxtImageButton,modalHeadingTxtSns,modalHeadingTxtButton,modalLink,modalTitleBlock,modalCaption,modalLine,modalTable1,modalTable2,modalTable2Single,modalTable3,modalTable4,modalTable5,modalTable4Single,modalButton,modalAbout,modalResearchAbout,modalTarget,modalTag,modalSmall,modalForCompany};
