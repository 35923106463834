import { Link, VStack, Image, Text } from '@chakra-ui/react'



const NewhopeEx = () => {
    return (
      <>
      <Link href={"https://newhope.g-mark.org/award2022.html#s08a"} isExternal _hover={{textDecoration:'none'}}>
        <VStack
          spacing={3}
          alignItems='center'
          p={8}
          w={{ base:"100%", md:300 }}
          borderRadius={{ base:20, md:14, lg:12 }}
          border="1px solid #D3D3D3"
          >
          <Image
            src={`/img/newhope.png`}
            alt="ニューホープ賞"
            objectFit="contain"
            w={{ base:"100%", md:240 }}
            />
          <Text fontSize={11} letterSpacing={0.2}>
            2022年度グッドデザイン・ニューホープ賞で第3の家族が最優秀賞を受賞しました。
          </Text>
        </VStack>
      </Link>
      </>
    );
  };

export default NewhopeEx;