import { Link, HStack, Image, Text, Flex } from '@chakra-ui/react'



const ForbesEx = () => {
    return (
      <>
      <Link href={"https://forbesjapan.com/feat/30under30/2023/honorees/"} isExternal _hover={{textDecoration:'none'}}>
        <HStack
          bg="white"
          spacing={3}
          alignItems='center'
          p={8}
          w={{ base:"100%", md:300 }}
          borderRadius={{ base:20, md:14, lg:12 }}
          border="1px solid #D3D3D3"
          >
          <Image
            src={`/img/forbes.png`}
            alt="FORBES JAPAN 30 UNDER 30 2023"
            objectFit="contain"
            h="74px"
            />
          <Text fontSize={11} letterSpacing={0.2}>
            FORBES JAPAN 30 UNDER 30 2023 「世界を変える30歳未満」に代表の奥村が選出されました。
          </Text>
        </HStack>
      </Link>
      </>
    );
  };

export default ForbesEx;