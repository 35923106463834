import {VStack,Text} from '@chakra-ui/react'
import { HashLink } from 'react-router-hash-link';

const ButtonNormal = ({to,text}) => {
    return(
        <HashLink smooth to={to}  w={{ base:"100%", md:300 }}>
            <VStack
            bg="black"
            px={4}
            py={3}
            color="white"
            fontSize={{base:14, md:16}}
            borderRadius={{ base:8, md:10, lg:10 }}
            lineHeight={1.7}
            w={{ base:"100%", md:300 }}
            h={{base:12,md:14}}
            justifyContent="center"
            >
                <Text>{text}</Text>
            </VStack>
        </HashLink>
    )
}
export default ButtonNormal;