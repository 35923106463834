import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import './index.css'
import Home from "./page/Home";
import Page404 from "./Page404";
import Header from "./page/section/Header";
import Footer from "./page/section/Footer";
import Privacy from "./page/parts/Privacy";
import Terms from "./page/parts/Terms";
import ScrollToTop from "./ScrollToTop";


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
